export const settingsTrans = {
  settings: "Ustawienia",
  storage: {
    title: "Ustawienia dysku",
    page_title: "Integracja z dyskiem",
    make_connection: "Połącz z dyskiem",
    remove_connection: "Usuń połączenie",
    disconnect_success: "Konto zostało pomyślnie odłączone",
    not_connected: "Twoje konto nie jest obecnie połączone z żadnym dyskiem",
    connected: "Połączono",
    actual_drive: "Aktualnie podłączony dysk",
    drive_name: "Nazwa dysku",
    disconnect_title: "Usuwanie połączenia z dyskiem",
    disconnect_confirm: "Tak, rozłącz",
    connect_to_drive: "Połącz z dyskiem",
    choose_drive: "Wybierz dysk",
    connect: "Połącz",
  },
  api_integration: {
    title: "Integracja API",
    webhooks: {
      title: "Webhooki",
      not_set: "nie ustawiono",
      webhook_not_set: "Aktualnie nie ustawiono adresu URL do wysyłki webhooków",
      info: "Aby otrzymywać komunikaty webhook skonfiguruj klucze API",
      set: "Ustaw URL do wysyłania webhooków",
      test: "Test konfiguracji Webhooka",
      response_status: "Status odpowiedzi",
      actual_set: "Aktualnie ustawiony Webhook URL",
      tooltip_text: "Wykonaj testowe żądanie HTTP na wskazany w konfiguracji URL",
      make_test: "Wykonaj test",
    },
    api_key: {
      title: "Twoje klucze API",
      keep_key: "Zachowaj podany klucz w bezpiecznym miejscu",
      never_display_again: "Nie zostanie on ponownie wyświetlony",
      public_key: "Klucz jawny",
      public_key_copy: "Skopiowałeś klucz jawny do schowka",
      secret_key: "Klucz tajny",
      secret_key_copy: "Skopiowałeś klucz tajny do schowka",
      deactivate: "Dezaktywuj",
      deactivated: "Dezaktywowany",
      search_via_public_key: "Wyszukaj po kluczu jawnym",
      show_deactivated: "Pokaż dezaktywowane klucze",
      creation_date: "Data utworzenia",
      not_found: "Nie znaleziono kluczy API",
      deactivate_question: "Czy na pewno chcesz dezaktywować klucz api?",
      deactivate_info: "Dezaktywowany klucz nie może być ponownie aktywowany.",
    },
  },
  password_change: {
    title: "Zmiana hasła",
    form: {
      password: {
        error: "Hasło musi zawierać co najmniej 6 znaków",
        label: "Obecne hasło",
        caps_lock: "Caps Lock jest włączony",
      },
    },
    button_label: "Wyślij email z linkiem do zmiany hasła",
  },
  delete_account: {
    title: "Usuwanie konta",
    delete_question: "Czy na pewno chcesz usunąć konto w SystemAML?",
    attention: "Uwaga",
    button_label: "Wyślij email z linkiem do usunięcia konta",
  },
  consents: {
    title: "Zgody",
    regulations: "Regulamin usługi System AML",
    politics: "Polityka prywatności usługi System AML",
    marketing_consent: "Przesyłanie informacji handlowych",
    consents_updated: "Zgody użytkownika zostały zaktualizowane"
  }
};
