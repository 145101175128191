import axios from "axios";
import * as constants from "../constants";
import { navigate } from "gatsby";
import i18next, {t} from "i18next";

import { attachmentsTrans as enAttachmentTrans } from "../../locales/en/attachments";
import { attachmentsTrans as plAttachmentTrans } from "../../locales/pl/attachments";

i18next.addResourceBundle("en", "attachments", enAttachmentTrans);
i18next.addResourceBundle("pl", "attachments", plAttachmentTrans);

const ROOT_URL = constants.ROOT_URL;
const FILE_UPLOAD_MAX_SIZE = parseInt(constants.FILE_UPLOAD_MAX_SIZE);

export function generalCall(type, url, method, formData = null, additionalOptions = null) {
  let options = PrepareAxiosOptions(url, method, formData, additionalOptions);
  return (dispatch) => {
    return axios(options)
      .then((response) => dispatch(generalSuccess(response, type)))
      .catch((error) => {
        try {
          if (error.message === "Network Error") {
            console.log(error);
          } else if (error.response.status === 401) {
            dispatch(loginRequired(error, formData));
          } else if (error.response.status >= 400 && error.response.status < 500) {
            if (error.response.status === 400 && error.response.data.message === "No application for user") {
              dispatch(generalError(error.response));
              return;
            }
            dispatch(errorResponse(error, type));
          } else if (error.response.status === 503) {
            dispatch(errorResponse(error, type));
          } else {
            dispatch(generalError(error.response));
          }
        } catch (e) {
          dispatch(generalError(error));
        }
      });
  };
}

export function simpleGeneralCall(url, method, formData = null, additionalOptions = null) {
  let options = PrepareAxiosOptions(url, method, formData, additionalOptions);
  return axios(options);
}

function GeneralCallPostFile(url, type, formData) {
  const authToken = localStorage.getItem("authToken");
  const lng = localStorage.getItem("i18nextLng");
  const isFileSizeToLarge = getFileAndCheckSize(formData);
  if (isFileSizeToLarge)
    return (dispatch) => {
      dispatch(fileToLarge(type));
    };

  const axiosConfig = {
    headers: {
      Accept: "application/json",
      "Accept-Language": lng || "en",
      Authorization: authToken,
    },
  };
  return (dispatch) => {
    return axios
      .post(url, formData, axiosConfig)
      .then((response) => dispatch(generalSuccess(response, type)))
      .catch((error) => {
        try {
          if (error.message === "Network Error") {
            console.log(error);
          } else if (error.response.status === 401) {
            dispatch(loginRequired(error, formData));
          } else if (error.response.status >= 400 && error.response.status < 500) {
            if (error.response.status === 400 && error.response.data.message === "No application for user") {
              dispatch(generalError(error.response));
              return;
            }
            dispatch(errorResponse(error, type));
          } else {
            dispatch(generalError(error.response));
          }
        } catch (e) {
          dispatch(generalError(error));
        }
      });
  };
}

function getFileAndCheckSize(formData) {
  const file = formData.get("file");
  return file && file.size > FILE_UPLOAD_MAX_SIZE;
}

export function PrepareAxiosOptions(url, method, formData, additionalOptions) {

  const lng = localStorage.getItem("i18nextLng");
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": lng || "en",
  };
  let authToken = localStorage.getItem("authToken");
  if (authToken) {
    headers["Authorization"] = authToken;
  }
  let options = {
    method: method,
    headers: headers,
    withCredentials: true,
  };
  if (additionalOptions) {
    options = { ...options, ...additionalOptions };
  }
  let apiUrl = url;
  if (method === "get" || method === "GET" || method === "delete" || method === "DELETE") {
    //TODO czy GET === get?
    if (formData) {
      apiUrl += "?" + new URLSearchParams(formData).toString();
    }
  } else {
    if (formData) {
      options.data = JSON.stringify(formData);
    }
  }
  options.url = apiUrl;
  return options;
}

export function clearState(type) {
  return {
    type: type,
    payload: { data: null },
  };
}

export const toggleSubscription = (isSubscribed) => ({
  type: constants.GET_SUBSCRIPTION_STATUS,
  payload: { data: isSubscribed },
});

export const getSubscriptionStatus = () => ({
  type: constants.GET_SUBSCRIPTION_STATUS,
});

export function generalSuccess(response, type) {
  return {
    type: type,
    payload: response,
  };
}

export function generalError(error) {
  return {
    type: constants.GENERAL_ERROR,
    payload: error.response,
  };
}

export function fileToLarge(type) {
  return {
    type: type,
    payload: {
      data: {
        message: `${t("attachments:ft.max_size", "Maximum file size is")} ${FILE_UPLOAD_MAX_SIZE / 1024 / 1024}MB`,
        status: "INFO",
      },
    },
  };
}

export function errorResponse(error, type) {
  return {
    type: type,
    payload: error.response,
  };
}

export function loginRequired(error, formData = false) {
  localStorage.removeItem("authToken");
  console.log(formData)
  if (!formData?.redirectOff) {
    navigate("/user/login/");
  } 
  return {
    type: constants.LOGIN_REQUIRED,
    payload: error.response,
  };
}

export function userRegister(formData) {
  const url = `${ROOT_URL}/int/user`;
  return generalCall(constants.USER_REGISTER, url, "post", formData);
}

export function userLogin(formData) {
  const url = `${ROOT_URL}/int/login`;
  return generalCall(constants.USER_LOGIN, url, "post", formData);
}

export function userCheckLoggedIn() {
  return () => {
    let token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/user/login/");
    }
  };
}

export function userLogout() {
  return (dispatch) => {
    localStorage.removeItem("authToken");
    dispatch({ type: constants.USER_LOGOUT });
  };
}

export function userResendVerificationEmail(formData) {
  let url = `${ROOT_URL}/int/user/email/verification-resend-email`;
  return generalCall(constants.USER_RESEND_VERIFICATION_EMAIL, url, "post", formData);
}

export function userSendResetPasswordEmail(formData) {
  let url = `${ROOT_URL}/int/user/password/send-reset-email`;
  return generalCall(constants.USER_SEND_RESET_PASSWORD_EMAIL, url, "post", formData);
}

export function changeUserPassword(formData) {
  let url = `${ROOT_URL}/int/user/password/change`;
  return generalCall(constants.USER_CHANGE_PASSWORD, url, "post", formData);
}

export function resetUserPassword(formData) {
  let url = `${ROOT_URL}/int/user/password/reset`;
  return generalCall(constants.USER_RESET_PASSWORD, url, "post", formData);
}

export function getUserConsents() {
  let url = `${ROOT_URL}/int/user/consents`;
  return generalCall(constants.GET_USER_CONSENTS, url, "get");
}

export function updateUserConsents(formData) {
  let url = `${ROOT_URL}/int/user/consents`;
  return generalCall(constants.UPDATE_USER_CONSENTS, url, "patch", formData);
}

export function updateUser(user) {
  return {
    type: constants.GET_USER,
    payload: { data: { data: user } },
  };
}

export function getUserTeams() {
  let url = `${ROOT_URL}/int/user/teams`;
  return generalCall(constants.GET_USER_TEAMS, url, 'get');
}
export function deleteTeamMember(teamCode, memberCode) {
  let url = `${ROOT_URL}/int/teams/${teamCode}/members/${memberCode}`;
  return generalCall(constants.DELETE_TEAM_MEMBER, url, 'delete');
}
export function updateTeamMemberRole(teamCode, memberCode, formData) {
  let url = `${ROOT_URL}/int/teams/${teamCode}/members/${memberCode}`;
  return generalCall(constants.UPDATE_TEAM_MEMBER_ROLE, url, 'patch', formData);
}
export function getTeamMembers(code) {
  let url = `${ROOT_URL}/int/teams/${code}/members`;
  return generalCall(constants.GET_TEAM_MEMBERS, url, 'get');
}
export function inviteTeamMember(formData) {
  let url = `${ROOT_URL}/int/teams/invite`;
  return generalCall(constants.INVITE_TEAM_MEMBERS, url, 'post', formData);
}
export function getTeamInvitationData(code) {
  let url = `${ROOT_URL}/int/teams/invite/${code}`;
  return generalCall(constants.GET_TEAM_INVITATION_DATA, url, 'get');
}
export function createNewTeam(formData) {
  let url = `${ROOT_URL}/int/teams`;
  return generalCall(constants.CREATE_TEAM, url, 'post', formData);
}
export function getTeamData(code) {
  let url = `${ROOT_URL}/int/teams/${code}`;
  return generalCall(constants.GET_TEAM_DATA, url, 'get');
}
export function deleteTeam(code) {
  let url = `${ROOT_URL}/int/teams/${code}`;
  return generalCall(constants.DELETE_TEAM, url, 'delete');
}
export function leaveTeam(code) {
  let url = `${ROOT_URL}/int/teams/${code}/leave`;
  return generalCall(constants.LEAVE_TEAM, url, 'delete');
}
export function updateTeamData(formData) {
  let url = `${ROOT_URL}/int/teams`;
  return generalCall(constants.UPDATE_TEAM_DATA, url, 'patch', formData);
}
export function teamMemberRegister(formData, code) {
  let url = `${ROOT_URL}/int/teams/invite/${code}`;
  return generalCall(constants.CREATE_TEAM_MEMBER, url, 'post', formData);
}
export function getTeamInvitations(formData) {
  let url = `${ROOT_URL}/int/teams/invite`;
  return generalCall(constants.GET_TEAM_INVITATIONS, url, 'get', formData)
}
export function deleteTeamInvitation(code) {
  let url = `${ROOT_URL}/int/teams/invite/${code}`;
  return generalCall(constants.DELETE_TEAM_INVITATION, url, 'delete')
}
export function handleTeamInvitation(formData, code) {
  let url = `${ROOT_URL}/int/teams/invite/${code}/handle`;
  return generalCall(constants.HANDLE_TEAM_INVITATION, url, 'post', formData);
}
export function changeCurrentTeamContext(formData) {
  let url = `${ROOT_URL}/int/teams/change`;
  return generalCall(constants.CHANGE_TEAM, url, 'post', formData);
}
export function getUser() {
  let url = `${ROOT_URL}/int/user`;
  return generalCall(constants.GET_USER, url, "get");
}
export function deleteUser(formData) {
  let url = `${ROOT_URL}/int/user/delete`;
  return generalCall(constants.DELETE_USER, url, "post", formData);
}

export function sendDeleteUserEmail() {
  let url = `${ROOT_URL}/int/users/email/delete-user`;
  return generalCall(constants.USER_SEND_DELETE_USER_EMAIL, url, "get");
}

export function getParties(formData) {
  const url = `${ROOT_URL}/int/parties`;
  return generalCall(constants.GET_PARTIES, url, "get", formData);
}

export function getParty(code) {
  const url = `${ROOT_URL}/int/parties/${code}`;
  return generalCall(constants.GET_PARTY, url, "get");
}
export function getPartySummaryStatus(code) {
  const url = `${ROOT_URL}/int/parties/${code}/summary`;
  return generalCall(constants.GET_PARTY_SUMMARY_STATUS, url, "get");
}

export function preparePartyReport(formData) {
  const url = `${ROOT_URL}/int/parties/pdf`;
  return generalCall(constants.PREPARE_PARTY_REPORT, url, "post", formData);
}

export function getReportList(formData) {
  const url = `${ROOT_URL}/int/reports/list`;
  return generalCall(constants.GET_REPORT_LIST, url, "get", formData);
}

export function getApplicant(code) {
  const url = `${ROOT_URL}/int/kyc/${code}`;
  return generalCall(constants.GET_APPLICANT, url, "get");
}

export function createApplicant(partyCode, formData) {
  const url = `${ROOT_URL}/int/parties/${partyCode}/applicants`;
  return generalCall(constants.CREATE_APPLICANT, url, "post", formData);
}

export function getApplicants(partyCode, queryParams) {
  const url = `${ROOT_URL}/int/parties/${partyCode}/applicants`;
  return generalCall(constants.GET_APPLICANTS, url, "get", queryParams);
}

export function getCurrentApplicant(partyCode) {
  const url = `${ROOT_URL}/int/parties/${partyCode}/applicants/current`;
  return generalCall(constants.GET_APPLICANTS, url, "get");
}

export function submitApplicant(code, formData) {
  const url = `${ROOT_URL}/int/kyc/${code}/form`;
  return generalCall(constants.GET_APPLICANT, url, "put", formData);
}

export function uploadFormAnswers(code, formData) {
  const url = `${ROOT_URL}/int/kyc/${code}/form`;
  return generalCall(constants.GET_APPLICANT, url, "post", formData);
}

export function getPartyAttachments(code) {
  const url = `${ROOT_URL}/int/parties/${code}/attachments`;
  return generalCall(constants.GET_PARTY_ATTACHMENTS, url, "get");
}

export function getPartyAttachmentsOneDrive(code) {
  const url = `${ROOT_URL}/int/parties/${code}/attachments/one-drive`;
  return generalCall(constants.GET_PARTY_ATTACHMENTS_ONE_DRIVE, url, "get");
}

export function createParty(formData) {
  const url = `${ROOT_URL}/int/parties`;
  return generalCall(constants.CREATE_PARTY, url, "post", formData);
}

export function getPartyRisk(code) {
  const url = `${ROOT_URL}/int/parties/${code}/risk`;
  return generalCall(constants.GET_PARTY_RISK, url, "get");
}

export function setPartyRiskStatus(code, formData) {
  const url = `${ROOT_URL}/int/parties/${code}/set-risk`;
  return generalCall(constants.SET_PARTY_RISK_STATUS, url, "post", formData);
}

export function updatePartyStatus(code, formData) {
  const url = `${ROOT_URL}/int/parties/${code}/status`;
  return generalCall(constants.UPDATE_PARTY_STATUS, url, "post", formData);
}

export function updateParty(formData) {
  const url = `${ROOT_URL}/int/parties`;
  return generalCall(constants.UPDATE_PARTY, url, "patch", formData);
}

export function deleteParty(code) {
  const url = `${ROOT_URL}/int/parties/${code}`;
  return generalCall(constants.DELETE_PARTY, url, "delete");
}

export function createHistoryEvent(formData) {
  const url = `${ROOT_URL}/int/history-events`;
  return generalCall(constants.CREATE_HISTORY_EVENT, url, "post", formData);
}

export function updateHistoryEvent(formData) {
  const url = `${ROOT_URL}/int/history-events`;
  return generalCall(constants.UPDATE_HISTORY_EVENT, url, "patch", formData);
}

export function getHistoryEvents(formData) {
  const url = `${ROOT_URL}/int/history-events`;
  return generalCall(constants.GET_HISTORY_EVENTS, url, "get", formData);
}

export function getHistoryEvent(code) {
  const url = `${ROOT_URL}/int/history-events/${code}`;
  return generalCall(constants.GET_HISTORY_EVENT, url, "get");
}

export function deleteHistoryEvent(code) {
  const url = `${ROOT_URL}/int/history-events/${code}`;
  return generalCall(constants.DELETE_HISTORY_EVENT, url, "delete");
}

export function createComment(formData) {
  const url = `${ROOT_URL}/int/comments`;
  return generalCall(constants.CREATE_COMMENT, url, "post", formData);
}

export function getEventComments(code, formData) {
  const url = `${ROOT_URL}/int/history-events/${code}/comments`;
  return generalCall(constants.GET_EVENT_COMMENTS, url, "get", formData);
}

export function updateComment(code, formData) {
  const url = `${ROOT_URL}/int/comments/${code}`;
  return generalCall(constants.UPDATE_COMMENT, url, "patch", formData);
}

export function deleteComment(code) {
  const url = `${ROOT_URL}/int/comments/${code}`;
  return generalCall(constants.DELETE_COMMENT, url, "delete");
}

export function addBeneficiary(code, formData) {
  const url = `${ROOT_URL}/int/parties/${code}/beneficiaries`;
  return generalCall(constants.ADD_BENEFICIARY, url, "post", formData);
}

export function deleteBeneficiary(code) {
  const url = `${ROOT_URL}/int/beneficiaries/${code}`;
  return generalCall(constants.DELETE_BENEFICIARY, url, "delete");
}

export function getBeneficiaries(code) {
  const url = `${ROOT_URL}/int/parties/${code}/beneficiaries`;
  return generalCall(constants.GET_BENEFICIARIES, url, "get");
}

export function updateBeneficiary(formData) {
  const url = `${ROOT_URL}/int/parties/beneficiaries`;
  return generalCall(constants.UPDATE_BENEFICIARY, url, "patch", formData);
}

export function getBoardMembers(code) {
  const url = `${ROOT_URL}/int/parties/${code}/boardmembers`;
  return generalCall(constants.GET_BOARDMEMBERS, url, "get");
}

export function addBoardMember(code, formData) {
  const url = `${ROOT_URL}/int/parties/${code}/boardmembers`;
  return generalCall(constants.ADD_BOARDMEMBER, url, "post", formData);
}

export function updateBoardMember(formData) {
  const url = `${ROOT_URL}/int/parties/boardmembers`;
  return generalCall(constants.UPDATE_BOARDMEMBER, url, "patch", formData);
}

export function deleteBoardMember(code) {
  const url = `${ROOT_URL}/int/boardmembers/${code}`;
  return generalCall(constants.DELETE_BOARDMEMBER, url, "delete");
}

export function getRegonAutofill(formData) {
  const url = `${ROOT_URL}/int/regon-autofill`;
  return generalCall(constants.GET_REGON_AUTOFILL, url, "get", formData);
}

export function getBeneficiariesByNIPFromCRBR(nip) {
  const url = `${ROOT_URL}/int/crbr-beneficiaries/${nip}`;
  return generalCall(constants.GET_BENEFICIARIES_BY_NIP_FROM_CRBR, url, "get");
}

export function checkSubscription() {
  const url = `${ROOT_URL}/int/subscriptions/active`;
  return generalCall(constants.CHECK_SUBSCRIPTION, url, "get");
}

export function getSubscriptions(formData) {
  const url = `${ROOT_URL}/int/subscriptions`;
  return generalCall(constants.GET_SUBSCRIPTIONS, url, "get", formData);
}

export function getSubscriptionPlans(formData) {
  const url = `${ROOT_URL}/int/orders/pricing`;
  return generalCall(constants.GET_SUBSCRIPTION_PLANS, url, "get", formData);
}


export function getOrders(formData) {
  const url = `${ROOT_URL}/int/orders`;
  return generalCall(constants.GET_ORDERS, url, "get", formData);
}

export function getOrder(code) {
  const url = `${ROOT_URL}/int/orders/${code}`;
  return generalCall(constants.GET_ORDER, url, "get");
}

export function getProductsData(type) {
  const url = `${ROOT_URL}/int/orders/pricing/${type}`;
  return generalCall(constants.GET_PRODUCTS_DATA, url, "get");
}

export function getOrderCalculation(formData) {
  const url = `${ROOT_URL}/int/orders/pricing/order-calculations`;
  return generalCall(constants.GET_ORDER_CALCULATION, url, "get", formData);
}

export function createTransaction(formData) {
  const url = `${ROOT_URL}/int/transactions`;
  return generalCall(constants.CREATE_TRANSACTION, url, "post", formData);
}

export function updateTransaction(formData) {
  const url = `${ROOT_URL}/int/transactions`;
  return generalCall(constants.UPDATE_TRANSACTION, url, "patch", formData);
}

export function getTransactionRisk(code) {
  const url = `${ROOT_URL}/int/transactions/${code}/risk`;
  return generalCall(constants.GET_TRANSACTION_RISK, url, "get");
}

export function setTransactionRiskStatus(code, formData) {
  const url = `${ROOT_URL}/int/transactions/${code}/set-risk`;
  return generalCall(constants.SET_TRANSACTION_RISK_STATUS, url, "post", formData);
}

export function updateTransactionStatus(code, formData) {
  const url = `${ROOT_URL}/int/transactions/${code}/status`;
  return generalCall(constants.UPDATE_TRANSACTION_STATUS, url, "post", formData);
}

export function getTransactions(formData) {
  const url = `${ROOT_URL}/int/transactions`;
  return generalCall(constants.GET_TRANSACTIONS, url, "get", formData);
}

export function prepareTransactionReport(formData) {
  const url = `${ROOT_URL}/int/transactions/pdf`;
  return generalCall(constants.PREPARE_TRANSACTION_REPORT, url, "post", formData);
}

export function getTransactionAttachments(code) {
  const url = `${ROOT_URL}/int/transactions/${code}/attachments`;
  return generalCall(constants.GET_TRANSACTION_ATTACHMENTS, url, "get");
}

export function getTransactionAttachmentsOneDrive(code) {
  const url = `${ROOT_URL}/int/transactions/${code}/attachments/one-drive`;
  return generalCall(constants.GET_TRANSACTION_ATTACHMENTS_ONE_DRIVE, url, "get");
}

export function createGoogleAuthToken(formData) {
  const url = `${ROOT_URL}/int/google/auth`;
  return generalCall(constants.CREATE_GOOGLE_AUTH_TOKEN, url, "post", formData);
}

export function getTransaction(code) {
  const url = `${ROOT_URL}/int/transactions/${code}`;
  return generalCall(constants.GET_TRANSACTION, url, "get");
}

export function getTransactionSummaryStatus(code) {
  const url = `${ROOT_URL}/int/transactions/${code}/summary`;
  return generalCall(constants.GET_TRANSACTION_SUMMARY_STATUS, url, "get");
}

export function deleteTransaction(code) {
  const url = `${ROOT_URL}/int/transactions/${code}`;
  return generalCall(constants.DELETE_TRANSACTION, url, "delete");
}

export function getAlerts(formData) {
  const url = `${ROOT_URL}/int/alerts`;
  return generalCall(constants.GET_ALERTS, url, "get", formData);
}

export function getAlert(code) {
  const url = `${ROOT_URL}/int/alerts/${code}`;
  return generalCall(constants.GET_ALERT, url, "get");
}

export function updateAlertStatus(code, formData) {
  const url = `${ROOT_URL}/int/alerts/status/${code}`;
  return generalCall(constants.UPDATE_ALERT_STATUS, url, "get", formData);
}

export function deleteAlert(code) {
  const url = `${ROOT_URL}/int/alerts/${code}`;
  return generalCall(constants.DELETE_ALERT, url, "delete");
}

export function createTask(formData) {
  const url = `${ROOT_URL}/int/tasks`;
  return generalCall(constants.CREATE_TASK, url, "post", formData);
}

export function getTasks(formData) {
  const url = `${ROOT_URL}/int/tasks`;
  return generalCall(constants.GET_TASKS, url, "get", formData);
}

export function getTask(code) {
  const url = `${ROOT_URL}/int/tasks/${code}`;
  return generalCall(constants.GET_TASK, url, "get");
}

export function updateTask(formData) {
  const url = `${ROOT_URL}/int/tasks`;
  return generalCall(constants.UPDATE_TASK, url, "patch", formData);
}

export function updateTaskStatus(code, formData) {
  const url = `${ROOT_URL}/int/tasks/status/${code}`;
  return generalCall(constants.UPDATE_TASK_STATUS, url, "get", formData);
}

export function deleteTask(code) {
  const url = `${ROOT_URL}/int/tasks/${code}`;
  return generalCall(constants.DELETE_TASK, url, "delete");
}

export function surveyCheck(formData) {
  const url = `${ROOT_URL}/int/surveys/check`;
  return generalCall(constants.SURVEY_CHECK, url, "post", formData);
}

export function surveySendEmail(formData) {
  const url = `${ROOT_URL}/int/surveys/send-email`;
  return generalCall(constants.SURVEY_SEND_EMAIL, url, "post", formData);
}

export function sanctionsListsSearch(formData) {
  const url = `${ROOT_URL}/int/sanctions-lists/search`;
  return generalCall(constants.SANCTIONS_LISTS_SEARCH, url, "post", formData);
}

export function sanctionsListsSearchNoForm(formData) {
  const url = `${ROOT_URL}/int/sanctions-lists/search-no-form`;
  return generalCall(constants.SANCTIONS_LISTS_SEARCH_NO_FORM, url, "post", formData);
}

export function sanctionsListsTransactionSearchNoForm(formData) {
  const url = `${ROOT_URL}/int/sanctions-lists/search-transaction-no-form`;
  return generalCall(constants.SANCTIONS_LISTS_SEARCH_TRANSACTION_NO_FORM, url, "post", formData);
}

export function partySanctionsListsScreen(partyCode, formData) {
  const url = `${ROOT_URL}/int/parties/${partyCode}/sanctions-lists/screen`;
  return generalCall(constants.PARTY_SANCTIONS_LISTS_SCREEN, url, "post", formData);
}

export function transactionSanctionsListsScreen(transactionCode, formData) {
  const url = `${ROOT_URL}/int/transactions/${transactionCode}/sanctions-lists/screen`;
  return generalCall(constants.TRANSACTION_SANCTIONS_LISTS_SCREEN, url, "post", formData);
}

export function getUserInvoiceData() {
  const url = `${ROOT_URL}/int/invoice-user-data`;
  return generalCall(constants.GET_USER_INVOICE_DATA, url, "get");
}

export function createOrUpdateUserInvoiceData(formData) {
  const url = `${ROOT_URL}/int/invoice-user-data`;
  return generalCall(constants.UPDATE_USER_INVOICE_DATA, url, "post", formData);
}

export function addPartyAttachment(code, formData) {
  const url = `${ROOT_URL}/int/parties/attachments/${code}`;
  return GeneralCallPostFile(url, constants.ADD_PARTY_ATTACHMENT, formData);
}

export function addPartyAttachmentOneDrive(code, formData) {
  const url = `${ROOT_URL}/int/parties/attachments/one-drive/${code}`;
  return GeneralCallPostFile(url, constants.ADD_PARTY_ATTACHMENT_ONE_DRIVE, formData);
}

export function getGoogleDriveInfo() {
  const url = `${ROOT_URL}/int/google/info`;
  return generalCall(constants.GOOGLE_INFO, url, "get");
}

export const getGoogleAccount = () => {
  const url = `${ROOT_URL}/int/google/accounts`;
  return generalCall(constants.GOOGLE_ACCOUNT_GET, url, "get");
};

export const getGoogleAccountConnectionRedirectUrl = () => {
  const url = `${ROOT_URL}/int/google/accounts/connections`;
  return generalCall(constants.GOOGLE_ACCOUNT_CONNECTION_REDIRECT_URL_GET, url, "get");
};

export const createGoogleAccountConnection = (formData) => {
  const url = `${ROOT_URL}/int/google/accounts/connections`;
  return generalCall(constants.GOOGLE_ACCOUNT_CONNECTION_CREATE, url, "post", formData);
};
export const disconnectGoogleAccount = () => {
  const url = `${ROOT_URL}/int/google/accounts/connections`;
  return generalCall(constants.GOOGLE_ACCOUNT_DISCONNECT, url, "delete");
};

export function googleLogin() {
  const url = `${ROOT_URL}/int/google/login`;
  return generalCall(constants.GOOGLE_LOGIN, url, "get");
}

export function googleLogout() {
  const url = `${ROOT_URL}/int/google/logout`;
  return generalCall(constants.GOOGLE_LOGOUT, url, "get");
}

export const getMicrosoftAccount = () => {
  const url = `${ROOT_URL}/int/microsoft/accounts`;
  return generalCall(constants.MICROSOFT_ACCOUNT_GET, url, "get");
};

export const getMicrosoftAccountConnectionRedirectUrl = () => {
  const url = `${ROOT_URL}/int/microsoft/accounts/connections`;
  return generalCall(constants.MICROSOFT_ACCOUNT_CONNECTION_REDIRECT_URL_GET, url, "get");
};

export const createMicrosoftAccountConnection = (formData) => {
  const url = `${ROOT_URL}/int/microsoft/accounts/connections`;
  return generalCall(constants.MICROSOFT_ACCOUNT_CONNECTION_CREATE, url, "post", formData);
};

export const disconnectMicrosoftAccount = () => {
  const url = `${ROOT_URL}/int/microsoft/accounts/connections`;
  return generalCall(constants.MICROSOFT_ACCOUNT_DISCONNECT, url, "delete");
};
export function addTransactionAttachment(code, formData) {
  const url = `${ROOT_URL}/int/transactions/attachments/${code}`;
  return GeneralCallPostFile(url, constants.ADD_TRANSACTION_ATTACHMENT, formData);
}

export function addTransactionAttachmentOneDrive(code, formData) {
  const url = `${ROOT_URL}/int/transactions/attachments/one-drive/${code}`;
  return GeneralCallPostFile(url, constants.ADD_TRANSACTION_ATTACHMENT_ONE_DRIVE, formData);
}

export function deleteAttachment(gdId, formData) {
  const url = `${ROOT_URL}/int/attachments/${gdId}`;
  return generalCall(constants.DELETE_ATTACHMENT, url, "delete", formData);
}

export function deleteAttachmentOneDrive(gdId, formData) {
  const url = `${ROOT_URL}/int/attachments/one-drive/${gdId}`;
  return generalCall(constants.DELETE_ATTACHMENT_ONE_DRIVE, url, "delete", formData);
}

export function updateAttachmentDescription(gdId, formData) {
  const url = `${ROOT_URL}/int/attachments/${gdId}`;
  return generalCall(constants.UPDATE_ATTACHMENT_DESCRIPTION, url, "patch", formData);
}

export function updateAttachmentDescriptionOneDrive(gdId, formData) {
  const url = `${ROOT_URL}/int/attachments/one-drive/${gdId}`;
  return generalCall(constants.UPDATE_ATTACHMENT_DESCRIPTION_ONE_DRIVE, url, "patch", formData);
}

export function uploadTransactionSignedXml(code, formData) {
  const url = `${ROOT_URL}/int/transactions/${code}/giif/signedxml`;
  return GeneralCallPostFile(url, constants.UPLOAD_TRANSACTION_SIGNED_XML, formData);
}

export function getTransactionFormData(code) {
  const url = `${ROOT_URL}/int/transactions/${code}/giif/form`;
  return simpleGeneralCall(url, "get");
}

export function getPartyFormData(code) {
  const url = `${ROOT_URL}/int/parties/${code}/giif/form`;
  return simpleGeneralCall(url, "get");
}

export function getNipFormData(nip) {
  const url = `${ROOT_URL}/int/giif/nip/${nip}`;
  return simpleGeneralCall(url, "get");
}

export function getRegonFormData(regon) {
  const url = `${ROOT_URL}/int/giif/regon/${regon}`;
  return simpleGeneralCall(url, "get");
}

export function sendGiifTransactionFormData(type, formData) {
  const url = `${ROOT_URL}/int/giif/form/${type}`;
  return simpleGeneralCall(url, "post", formData);
}

export function uploadTransactionSignedFormXml(formData) {
  const url = `${ROOT_URL}/int/giif/form/upload`;
  return GeneralCallPostFile(url, constants.UPLOAD_TRANSACTION_SIGNED_FORM_XML, formData);
}

export function createFinancialSecurityMeasuresExecution(formData) {
  const url = `${ROOT_URL}/int/financial-security-measures-executions`;
  return generalCall(constants.FINANCIAL_SECURITY_MEASURES_EXECUTION_CREATE, url, "post", formData);
}

export function updateFinancialSecurityMeasuresExecution(code, formData) {
  const url = `${ROOT_URL}/int/financial-security-measures-executions/${code}`;
  return generalCall(constants.FINANCIAL_SECURITY_MEASURES_EXECUTION_FIND, url, "post", formData);
}

export function findFinancialSecurityMeasuresExecution(code) {
  const url = `${ROOT_URL}/int/financial-security-measures-executions/${code}`;
  return generalCall(constants.FINANCIAL_SECURITY_MEASURES_EXECUTION_FIND, url, "get");
}

export function deleteFinancialSecurityMeasureExecution(code) {
  const url = `${ROOT_URL}/int/financial-security-measures-executions/${code}`;
  return generalCall(constants.DELETE_FINANCIAL_SECURITY_MEASURES_EXECUTION, url, "delete");
}

export function getFinancialSecurityMeasuresExecutions(partyCode, formData) {
  const url = `${ROOT_URL}/int/parties/${partyCode}/financial-security-measures-executions`;
  return generalCall(constants.FINANCIAL_SECURITY_MEASURES_EXECUTIONS_GET, url, "get", formData);
}

export function uploadFinancialSecurityMeasureAttachment(code, formData) {
  const url = `${ROOT_URL}/int/financial-security-measures/${code}/attachments`;
  return GeneralCallPostFile(url, constants.FINANCIAL_SECURITY_MEASURES_UPLOAD_ATTACHMENT, formData);
}
export function uploadFinancialSecurityMeasureAttachmentOneDrive(code, formData) {
  const url = `${ROOT_URL}/int/financial-security-measures/${code}/attachments/one-drive`;
  return GeneralCallPostFile(url, constants.FINANCIAL_SECURITY_MEASURES_UPLOAD_ATTACHMENT_ONE_DRIVE, formData);
}

export function deleteFinancialSecurityMeasureAttachment(measureCode, attachmentCode) {
  const url = `${ROOT_URL}/int/financial-security-measures/${measureCode}/attachments/${attachmentCode}`;
  return generalCall(constants.FINANCIAL_SECURITY_MEASURES_EXECUTION_FIND, url, "delete");
}

export function getRiskManagementStatus() {
  const url = `${ROOT_URL}/int/risk-management`;
  return generalCall(constants.GET_RISK_MANAGEMENT_STATUS, url, "get");
}

export function updateRiskManagementStatus(formData) {
  const url = `${ROOT_URL}/int/risk-management`;
  return generalCall(constants.UPDATE_RISK_MANAGEMENT_STATUS, url, "post", formData);
}

export function getAvailableRules(formData) {
  const url = `${ROOT_URL}/int/rules/all`;
  return generalCall(constants.GET_AVAILABLE_RULES, url, "get", formData);
}

export function getRule(code) {
  const url = `${ROOT_URL}/int/rules/${code}`;
  return generalCall(constants.GET_RULE, url, "get");
}

export function getRuleLimits(code) {
  const url = `${ROOT_URL}/int/rules/${code}/limits`;
  return generalCall(constants.GET_RULE_LIMITS, url, "get");
}

export function getUserRules(formData) {
  const url = `${ROOT_URL}/int/rules`;
  return generalCall(constants.GET_USER_RULES, url, "get", formData);
}

export function getSuggestedRiskStatusRecalculate(formData) {
  const url = `${ROOT_URL}/int/rules/risk-recalculate`;
  return generalCall(constants.GET_SUGGESTED_RISK_STATUS_RECALCULATE, url, "post", formData);
}

export function addUserRule(code) {
  const url = `${ROOT_URL}/int/rules/${code}`;
  return generalCall(constants.ADD_USER_RULE, url, "post");
}

export function removeUserRule(code) {
  const url = `${ROOT_URL}/int/rules/${code}`;
  return generalCall(constants.REMOVE_USER_RULE, url, "delete");
}

export function updateRuleLimits(code, formData) {
  const url = `${ROOT_URL}/int/rules/${code}/limits`;
  return generalCall(constants.UPDATE_RULE_LIMITS, url, "post", formData);
}

export function updateHighRiskPkdList(formData) {
  const url = `${ROOT_URL}/int/pkd/high-risk`;
  return generalCall(constants.UPDATE_HIGH_RISK_PKD_LIST, url, "post", formData);
}

export function getIndividualCustomerProfileList(code) {
  const url = `${ROOT_URL}/int/profile/individual-customer/${code}`;
  return generalCall(constants.GET_INDIVIDUAL_CUSTOMER_PROFILE, url, "get");
}
export function updateIndividualCustomerProfileList(formData, code) {
  const url = `${ROOT_URL}/int/profile/individual-customer/${code}`;
  return generalCall(constants.UPDATE_INDIVIDUAL_CUSTOMER_PROFILE, url, "post", formData);
}
export function deleteIndividualCustomerProfile(code) {
  const url = `${ROOT_URL}/int/profile/individual-customer/${code}`;
  return generalCall(constants.DELETE_INDIVIDUAL_CUSTOMER_PROFILE, url, "delete");
}
export function createIndividualCustomerProfile(formData) {
  const url = `${ROOT_URL}/int/profile/individual-customer`;
  return generalCall(constants.CREATE_INDIVIDUAL_CUSTOMER_PROFILE, url, "post", formData);
}

export function createHighRiskCountryList(formData) {
  const url = `${ROOT_URL}/int/country/high-risk`;
  return generalCall(constants.CREATE_HIGH_RISK_COUNTRY_LIST, url, "post", formData);
}

export function updateHighRiskCountryList(formData) {
  const url = `${ROOT_URL}/int/country/high-risk`;
  return generalCall(constants.UPDATE_HIGH_RISK_COUNTRY_LIST, url, "patch", formData);
}

export function deleteHighRiskCountryList() {
  const url = `${ROOT_URL}/int/country/high-risk`;
  return generalCall(constants.DELETE_HIGH_RISK_COUNTRY_LIST, url, "delete");
}

export function createCorruptCountryList(formData) {
  const url = `${ROOT_URL}/int/country/corrupt`;
  return generalCall(constants.CREATE_CORRUPT_COUNTRY_LIST, url, "post", formData);
}

export function updateCorruptCountryList(formData) {
  const url = `${ROOT_URL}/int/country/corrupt`;
  return generalCall(constants.UPDATE_CORRUPT_COUNTRY_LIST, url, "patch", formData);
}

export function deleteCorruptCountryList() {
  const url = `${ROOT_URL}/int/country/corrupt`;
  return generalCall(constants.DELETE_CORRUPT_COUNTRY_LIST, url, "delete");
}

export function createTaxHavenCountryList(formData) {
  const url = `${ROOT_URL}/int/country/tax-haven`;
  return generalCall(constants.CREATE_TAX_HAVEN_COUNTRY_LIST, url, "post", formData);
}

export function updateTaxHavenCountryList(formData) {
  const url = `${ROOT_URL}/int/country/tax-haven`;
  return generalCall(constants.UPDATE_TAX_HAVEN_COUNTRY_LIST, url, "patch", formData);
}

export function deleteTaxHavenCountryList() {
  const url = `${ROOT_URL}/int/country/tax-haven`;
  return generalCall(constants.DELETE_TAX_HAVEN_COUNTRY_LIST, url, "delete");
}

export function createSanctionCountryList(formData) {
  const url = `${ROOT_URL}/int/country/sanction`;
  return generalCall(constants.CREATE_SANCTION_COUNTRY_LIST, url, "post", formData);
}

export function updateSanctionCountryList(formData) {
  const url = `${ROOT_URL}/int/country/sanction`;
  return generalCall(constants.UPDATE_SANCTION_COUNTRY_LIST, url, "patch", formData);
}

export function deleteSanctionCountryList() {
  const url = `${ROOT_URL}/int/country/sanction`;
  return generalCall(constants.DELETE_SANCTION_COUNTRY_LIST, url, "delete");
}

export function createTerrorismCountryList(formData) {
  const url = `${ROOT_URL}/int/country/terrorism`;
  return generalCall(constants.CREATE_TERRORISM_COUNTRY_LIST, url, "post", formData);
}

export function updateTerrorismCountryList(formData) {
  const url = `${ROOT_URL}/int/country/terrorism`;
  return generalCall(constants.UPDATE_TERRORISM_COUNTRY_LIST, url, "patch", formData);
}

export function deleteTerrorismCountryList() {
  const url = `${ROOT_URL}/int/country/terrorism`;
  return generalCall(constants.DELETE_TERRORISM_COUNTRY_LIST, url, "delete");
}

export function addMissingRuleData(code, formData) {
  const url = `${ROOT_URL}/int/rules/${code}/missing-data`;
  return generalCall(constants.ADD_MISSING_RULE_DATA, url, "post", formData);
}

export function rejectRuleResult(code) {
  const url = `${ROOT_URL}/int/result/${code}/reject`;
  return generalCall(constants.REJECT_RULE_RESULTS, url, "get");
}

export function setRuleResultStatus(code, formData) {
  const url = `${ROOT_URL}/int/result/${code}/status`;
  return generalCall(constants.SET_RULE_RESULTS_STATUS, url, "post", formData);
}

export function logSwitch(formData) {
  const url = `${ROOT_URL}/int/admin/logswitch`;
  return generalCall(constants.LOG_SWITCH, url, "post", formData);
}

export function createEmployees(formData) {
  const url = `${ROOT_URL}/int/employees`;
  return generalCall(constants.CREATE_EMPLOYEES, url, "post", formData);
}

export function updateEmployees(formData, code) {
  const url = `${ROOT_URL}/int/employees/${code}`;
  return generalCall(constants.UPDATE_EMPLOYEES, url, "patch", formData);
}

export function getEmployees(formData) {
  let url = `${ROOT_URL}/int/employees`;
  return generalCall(constants.GET_EMPLOYEES, url, "get", formData);
}

export function getEmployee(code) {
  let url = `${ROOT_URL}/int/employees/${code}`;
  return generalCall(constants.GET_EMPLOYEE, url, "get");
}

export function deleteEmployee(code) {
  const url = `${ROOT_URL}/int/employees/${code}`;
  return generalCall(constants.DELETE_EMPLOYEE, url, "delete");
}

export function addEmployeeTraining(formData) {
  let url = `${ROOT_URL}/int/trainings`;
  return GeneralCallPostFile(url, constants.ADD_EMPLOYEE_TRAINING, formData);
}

export function getEmployeeTrainings(code, formData) {
  let url = `${ROOT_URL}/int/employees/${code}/trainings`;
  return generalCall(constants.GET_EMPLOYEE_TRAININGS, url, "get", formData);
}

export function deleteEmployeeTraining(code) {
  const url = `${ROOT_URL}/int/employees/training/${code}`;
  return generalCall(constants.DELETE_EMPLOYEE_TRAINING, url, "delete");
}

export function createApiKeys() {
  const url = `${ROOT_URL}/int/user/apikeys`;
  return generalCall(constants.CREATE_APIKEYS, url, "post");
}

export function getApiKeys(formData) {
  let url = `${ROOT_URL}/int/user/apikeys`;
  return generalCall(constants.GET_APIKEYS, url, "get", formData);
}

export function getAllActiveApiKeys() {
  let url = `${ROOT_URL}/int/user/apikeys`;
  return generalCall(constants.GET_ALL_ACTIVE_API_KEYS, url, "get", { showAll: false });
}

export function disableApiKeys(apiKey) {
  const url = `${ROOT_URL}/int/user/apikeys/${apiKey}`;
  return generalCall(constants.DISABLE_APIKEYS, url, "patch");
}
export function uploadPartyCsv(formData, partyType) {
  let url = `${ROOT_URL}/int/parties-import/csv/`;
  url += "?" + new URLSearchParams({ partyType: partyType }).toString();
  return GeneralCallPostFile(url, constants.UPLOAD_PARTY_CSV, formData);
}

export function sendEmailVerificationCode(code) {
  const url = `${ROOT_URL}/int/users/email/verify`;
  return generalCall(constants.USER_EMAIL_VERIFICATION, url, "post", { code });
}

export function setCallbackURL(callbackUrl) {
  const url = `${ROOT_URL}/int/users/callback-url`;
  return generalCall(constants.GET_USER, url, "post", { callbackUrl });
}

export function makeCallbackTestRequest(callbackUrl) {
  const url = `${ROOT_URL}/int/users/callback-url/test-request`;
  return generalCall(constants.MAKE_TEST_CALLBACK_REQUEST, url, "post", { callbackUrl });
}

export function createRegistriesSkanReport(formData) {
  const url = `${ROOT_URL}/int/registries-reports`;
  return generalCall(constants.CREATE_REGISTRIES_SKAN_REPORT, url, "post", formData);
}

export function createPPVAmlParty(code, formData) {
  const url = `${ROOT_URL}/int/ppv/${code}/party`;
  return generalCall(constants.CREATE_PPV_AML_PARTY, url, "post", formData);
}
export function getPpvRequest(code) {
  const url = `${ROOT_URL}/int/ppv/${code}`;
  return generalCall(constants.GET_PPV_REQUEST, url, "get");
}
export function getPpvParty(code) {
  const url = `${ROOT_URL}/int/ppv/${code}/party`;
  return generalCall(constants.GET_PPV_PARTY, url, "get");
}
export function updatePpvParty(code, formData) {
  const url = `${ROOT_URL}/int/ppv/${code}/party`;
  return generalCall(constants.UPDATE_PPV_PARTY, url, "patch", formData);
}
export function getPpvRuleSet() {
  const url = `${ROOT_URL}/int/ppv/rule-set`;
  return generalCall(constants.GET_PPV_RULE_SET, url, "get");
}
export function getPpvRuleSetDescription() {
  const url = `${ROOT_URL}/int/ppv/rule-set/description`;
  return generalCall(constants.GET_PPV_RULE_SET_DESCRIPTION, url, "get");
}
export function getPpvPartyRisk(code) {
  const url = `${ROOT_URL}/int/ppv/${code}/risk`;
  return generalCall(constants.GET_PPV_PARTY_RISK, url, "get");
}
export function ppvRejectRuleResult(code, formData) {
  const url = `${ROOT_URL}/int/ppv/${code}/result-reject`;
  return generalCall(constants.PPV_REJECT_RULE_RESULTS, url, "post", formData);
}
export function ppvRiskRecalculate(code) {
  const url = `${ROOT_URL}/int/ppv/${code}/risk-recalculate`;
  return generalCall(constants.PPV_RECALCULATE_RESULTS, url, "post");
}
export function getPpvHistoryEvents(code, formData) {
  const url = `${ROOT_URL}/int/ppv/${code}/events`;
  return generalCall(constants.GET_PPV_EVENTS, url, "get", formData);
}
export function getPpvHistoryEvent(ppvCode, code) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/events/${code}`;
  return generalCall(constants.GET_PPV_EVENT_DETAILS, url, "get");
}
export function deletePpvHistoryEvent(ppvCode, code) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/events/${code}`;
  return generalCall(constants.DELETE_PPV_EVENT, url, "delete");
}
export function getPpvReportDetails(code) {
  const url = `${ROOT_URL}/int/ppv/${code}/report/details`;
  return generalCall(constants.GET_PPV_REPORT_DETAILS, url, "get");
}
export function deletePpvCustomerDueDiligenceExecutions(ppvCode, code) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/customer-due-diligence-executions/${code}`;
  return generalCall(constants.DELETE_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS, url, "delete");
}
export function getPpvCustomerDueDiligenceExecutions(code) {
  const url = `${ROOT_URL}/int/ppv/${code}/customer-due-diligence-executions`;
  return generalCall(constants.GET_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS, url, "get");
}
export function createPpvCustomerDueDiligenceExecution(formData) {
  const url = `${ROOT_URL}/int/ppv/customer-due-diligence-executions`;
  return generalCall(constants.CREATE_PPV_CUSTOMER_DUE_DILIGENCE_EXECUTIONS, url, "post", formData);
}
export function findPpvCustomerDueDiligenceExecution(code, ppvCode) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/customer-due-diligence-executions/${code}`;
  return generalCall(constants.PPV_FIND_DUE_DILIGENCE_EXECUTION, url, "get");
}
export function updatePpvCustomerDueDiligenceExecution(code, ppvCode, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/customer-due-diligence-executions/${code}`;
  return generalCall(constants.PPV_FIND_DUE_DILIGENCE_EXECUTION, url, "post", formData);
}
export function preparePpvReport(ppvCode) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/pdf`;
  return generalCall(constants.PPV_PREPARE_REPORT, url, "get");
}
export function getPpvReportsList(ppvCode) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/list/pdf`;
  return generalCall(constants.PPV_GET_PDF_LIST, url, "get");
}
export function screenPpvParty(ppvCode) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/sanctions-lists/screen`;
  return generalCall(constants.PPV_PARTY_SCREEN, url, "get");
}
export function setPpvRuleResultStatus(ppvCode, code, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/result/${code}/status`;
  return generalCall(constants.SET_PPV_RULE_RESULT_STATUS, url, "post", formData);
}
export function getPpvBeneficiaries(ppvCode) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/beneficiaries`;
  return generalCall(constants.GET_PPV_BENEFICIARIES, url, "get");
}
export function deletePpvBeneficiary(ppvCode, code) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/beneficiaries/${code}`;
  return generalCall(constants.DELETE_PPV_BENEFICIARY, url, "delete");
}
export function addPpvBeneficiary(ppvCode, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/beneficiaries`;
  return generalCall(constants.ADD_PPV_BENEFICIARY, url, "post", formData);
}
export function updatePpvBeneficiary(ppvCode, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/beneficiaries`;
  return generalCall(constants.UPDATE_PPV_BENEFICIARY, url, "patch", formData);
}
export function getPpvBoardMembers(ppvCode) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/boardmembers`;
  return generalCall(constants.GET_PPV_BOARDMEMBERS, url, "get");
}
export function addPpvBoardMember(code, formData) {
  const url = `${ROOT_URL}/int/ppv/${code}/boardmembers`;
  return generalCall(constants.ADD_PPV_BOARDMEMBER, url, "post", formData);
}
export function deletePpvBoardMember(ppvCode, code) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/boardmembers/${code}`;
  return generalCall(constants.DELETE_PPV_BOARDMEMBER, url, "delete");
}
export function updatePpvBoardMember(ppvCode, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/boardmembers`;
  return generalCall(constants.UPDATE_PPV_BOARDMEMBER, url, "patch", formData);
}
export function getRuleDefaultLimits(code) {
  const url = `${ROOT_URL}/int/rules/${code}/default-limits`;
  return generalCall(constants.GET_PPV_RULE_LIMITS, url, "get");
}
export function getPpvEventComments(ppvCode, code, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/events/${code}/comments`;
  return generalCall(constants.GET_PPV_EVENT_COMMENTS, url, "get", formData);
}
export function createPpvEventComment(ppvCode, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/comments`;
  return generalCall(constants.CREATE_PPV_EVENT_COMMENT, url, "post", formData);
}
export function deletePpvComment(ppvCode, code) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/comments/${code}`;
  return generalCall(constants.DELETE_PPV_COMMENT, url, "delete");
}
export function updatePpvComment(ppvCode, code, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/comments/${code}`;
  return generalCall(constants.UPDATE_PPV_COMMENT, url, "patch", formData);
}
export function updatePpvHistoryEvent(ppvCode, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/events`;
  return generalCall(constants.UPDATE_PPV_HISTORY_EVENT, url, "patch", formData);
}
export function createPpvHistoryEvent(ppvCode, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/events`;
  return generalCall(constants.CREATE_PPV_HISTORY_EVENT, url, "post", formData);
}
export function addPpvMissingRuleData(ppvCode, code, formData) {
  const url = `${ROOT_URL}/int/ppv/${ppvCode}/rules/${code}/missing-data`;
  return generalCall(constants.ADD_PPV_MISSING_RULE_DATA, url, "post", formData);
}
export function setLanguage(formData) {
  const url = `${ROOT_URL}/int/language`;
  return generalCall(constants.SET_LANGUAGE, url, 'post', formData)
}

export function getShopProducts() {
  const url = `${ROOT_URL}/int/shop/products`;
  return generalCall(constants.GET_SHOP_PRODUCTS, url, "get");
}

export function getShopCart() {
  let url = `${ROOT_URL}/int/shop/cart`;
  return generalCall(constants.GET_SHOP_CART, url, "get");
}

export function addShopItem(formData) {
  let url = `${ROOT_URL}/int/shop/cart/item`;
  return generalCall(constants.ADD_SHOP_ITEM, url, "post", formData);
}

export function deleteShopItem(formData) {
  let url = `${ROOT_URL}/int/shop/cart/item`;
  return generalCall(constants.DELETE_SHOP_ITEM, url, "delete", formData);
}

export function updateShopItem(formData) {
  let url = `${ROOT_URL}/int/shop/cart/item`;
  return generalCall(constants.UPDATE_SHOP_ITEM, url, "patch", formData);
}

export function addDiscountCode(formData) {
  let url = `${ROOT_URL}/int/shop/cart/discount-code`;
  return generalCall(constants.ADD_DISCOUNT_CODE, url, "post", formData);
}

export function deleteDiscountCode(formData) {
  let url = `${ROOT_URL}/int/shop/cart/discount-code`;
  return generalCall(constants.DELETE_DISCOUNT_CODE, url, "delete", formData);
}

export function createOrder(formData) {
  const url = `${ROOT_URL}/int/shop/cart/createOrder`;
  return generalCall(constants.CREATE_ORDER, url, "post", formData);
}

export function cancelOrder(code) {
  const url = `${ROOT_URL}/int/shop/orders/${code}`;
  return generalCall(constants.CANCEL_ORDER, url, "delete");
}

export function getSession() {
  const url = `${ROOT_URL}/int/session`;
  return generalCall(constants.GET_SESSION, url, "get");
}
